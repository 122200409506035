import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { Error404Content } from '@/modules/errors/components/Error404Content';
import { Layout } from '@/modules/layout/components/Layout';
import { SimpleFooter } from '@/modules/layout/components/SimpleFooter';
import { SimpleNavBar } from '@/modules/layout/components/SimpleNavBar';

const Error404 = () => (
  <Layout navbar={<SimpleNavBar />} footer={<SimpleFooter />}>
    <Error404Content />
  </Layout>
);

export default Error404;

export async function getStaticProps({ locale = 'pl' }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['errors', 'layout', 'common'])),
    },
  };
}
